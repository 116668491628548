.footer {
	align-items: flex-end;
	background-image: url(../../img/footer-bg.png);
	background-size: 100% 100%;
	bottom: 0;
	display: flex;
	font-size: 13px;
	height: 70px;
	left: 50%;
	max-width: 540px;
	padding-block: 0 0.10333rem;
	position: fixed;
	-webkit-transform: translateX(-50%);
	transform: translate(-50%);
	width: 100%;
	z-index: 100;
}
.footer-item {
	-webkit-box-flex: 1;
	-webkit-flex: 1;
	flex: 1;
	display: -webkit-box;
	display: -webkit-flex;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: center;
	-webkit-align-items: center;
	align-items: center;
	-webkit-box-pack: end;
	-webkit-justify-content: flex-end;
	justify-content: flex-end;
	gap: 0.11707rem;
	color: #949494;
}
.footer-item a {
	display: block;
	color: #fff;
}
.footer-item .icon_footer {
	margin-bottom: 0.1rem;
	text-align: center;
	position: relative;
	z-index: 1;
}
.footer-item .icon_footer svg {
	width: 0.6776rem;
	height: 0.69387rem;
}
.footer-center-bg {
	position: absolute;
	left: 50%;
	bottom: 5px;
	-webkit-transform: translate(-50%, 0);
	transform: translate(-50%);
	background: radial-gradient(#ff6f00, #d8ae80);
	width: 65px;
	height: 65px;
	border-radius: 50%;
	box-shadow: 0 -0.08rem 0.16rem #d0d0ed80;
	z-index: 0;
}
.footer-item:nth-child(3) .icon_footer div {
	position: relative;
	color: #fff !important;
	top: -10px;
	background-size: auto 38px;
	width: 38px;
	height: 38px;
	z-index: 3;
}
.footer-item:nth-child(3) .icon_footer * {
	color: #fff;
}
.footer-item:has(.active) * {
	color: #fff;
}
.title_footer {
	white-space: nowrap;
}
.footer-item .footer-icon {
	background-repeat: no-repeat;
	background-size: auto 35px;
	width: 35px;
	height: 35px;
	display: block;
	margin: auto;
}
.footer-icon-cskh {
	background-image: url(../../img/ic-service.png);
}
.footer-icon-nap {
	background-image: url(../../img/ic-nap.png);
}
.footer-icon-rut {
	background-image: url(../../img/ic-rut.png);
}
.footer-icon-home {
	background-image: url(../../img/ic-home.png);
}
.footer-icon-user {
	background-image: url(../../img/ic-profile.png);
}
/*@media (max-width: 600px) {
	.footer {
		font-size: 0.25rem;
		height: 1.3rem;
		padding-bottom: 0.1rem;
	}
	.footer-center-bg {
		width: 1.02rem;
		height: 1.02rem;
		bottom: 0.55rem;	
	}
	.footer-center-bg:after {
		width: 0.9rem;
		height: 0.9rem;
	}
	.footer-item .icon_footer svg {
		width: 0.5rem;
		height: 0.52rem;
	}
	.footer-item:nth-child(3) .icon_footer svg {
		top: -0.2rem;
	}
}*/
